import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.for-each.js";
//import { requestIp } from 'request-ip'
import { FadeTransition } from "vue2-transitions";
import Focus from "@/components/Focus";
import { required, minLength,
// maxLength,
email, phone, symbols, equal, FormMixin, default as UiForm } from "@/components/Form/Form";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import BillingPromocodeNotice from "@/components/Billing/PromocodeNotice";

//let carrotquest = window.carrotquest
//import CQ_tarif from "@/components/CarrotQuest/CarrotQuest.js";
//const { CQ_register, CQ_tarif } = require("@/components/CarrotQuest/CarrotQuest.js");
const {
  CQ_tarif
} = require("@/components/CarrotQuest/CarrotQuest.js");
const _emailCache = {};
const emailAvailable = (vm, inverse, msg) => async v => {
  //if (vm.length <= 5) return;

  //console.log('10: vm=', vm)
  if (_emailCache[v] === undefined) {
    _emailCache[v] = await vm.$store.dispatch("user/checkEmail", {
      user_email_address: v
    });
  }
  if (!inverse && !_emailCache[v] || inverse && _emailCache[v]) {
    return msg || "Email уже занят";
  }
};
const _phoneCache = {};
const phoneAvailable = (vm, inverse, msg) => async (v, items, phoneItem) => {
  // //console.log('20: vm=', vm)
  // //console.log('20: v=', v)
  // //console.log('20: items=', items)
  // //console.log('20: phoneItem=', phoneItem)

  const phone = phoneItem.fullPhone(); // = formatInternational
  //const phone = v.fullPhone();
  //console.log('Form.267: phone=', phone)

  const pswd = vm.active_inputs.find(i => i.name === "user_password").model.trim();
  //console.log("Form.322: pswd=",pswd)

  //console.log("Form.324: _phoneCache[phone]=",_phoneCache[phone])

  try {
    if (_phoneCache[phone] === undefined) {
      //console.log("Form.329: -> user/checkPhone")

      _phoneCache[phone] = await vm.$store.dispatch("user/checkPhone", {
        user_phone: phone,
        user_password: pswd
      });
      if (_phoneCache[phone].status === 201) {
        vm.codeCheckStatus = 201;
        vm.codeTrys === -10;
      }
    }
  } catch (e) {
    var _e$response, _e$response2;
    if (((_e$response = e.response) === null || _e$response === void 0 ? void 0 : _e$response.status) === 409) {
      msg = "Телефон уже используется";
    } else if (((_e$response2 = e.response) === null || _e$response2 === void 0 ? void 0 : _e$response2.status) === 418) {
      msg = `К сожалению, было сделано слишком много
            неудачных попыток подтверждения этого номера телефона<br/>
            Для Регистрации обратитесь в нашу службу поддержки
            <a href="mailto:support@salesfinder.ru" target="_blank">support@salesfinder.ru</a>`;
    }
  }
  //console.log("Form.337: _phoneCache[phone]=", _phoneCache[phone]);

  if (!inverse && !_phoneCache[phone] || inverse && _phoneCache[phone]) {
    //return msg || "Телефон уже используется";
    return msg;
  }
};
const SMS_TIMER = 60;
const SMS_CODE_TRY = 3;
const SMS_CODE_LEN = 4;
export default {
  mixins: [FormMixin],
  props: {
    action: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      active_inputs: [],
      inputs: {
        register: [{
          name: "user_email_address",
          title: "Email",
          placeholder: "Введите ваш email",
          type: "email",
          error_text: "",
          show_error: false,
          show_success: false,
          model: "",
          active: false,
          touched: false,
          validators: [required(), email(), emailAvailable(this)],
          show_name: false
        }, {
          name: "user_phone",
          title: "Телефон",
          placeholder: "Введите ваш номер телефона",
          type: "tel",
          error_text: "",
          show_error: false,
          show_success: false,
          model: "",
          active: false,
          touched: false,
          validators: [required(), phone(), phoneAvailable(this)],
          show_name: false,
          phoneValid: () => this.phoneIsValid,
          fullPhone: () => this.fullPhone
        }, /**/ //закомментировать при регистрации SKIP-SMS
        {
          name: "user_code",
          title: "",
          placeholder: `Введите ${SMS_CODE_LEN} цифры из смс`,
          type: "text",
          error_text: "",
          show_error: false,
          show_success: false,
          model: "",
          active: false,
          touched: false,
          validators: [required(), minLength(SMS_CODE_LEN)],
          show_name: false
        }, /**/

        {
          title: "Пароль",
          name: "user_password",
          placeholder: "Введите пароль, минимум 5 символов",
          type: "password",
          error_text: "",
          show_error: false,
          show_success: false,
          show_password: false,
          model: "",
          active: false,
          touched: false,
          validators: [required(), minLength(5, "Слишком короткий пароль"), symbols()],
          show_name: false
        }, {
          title: "Повторите пароль",
          name: "_user_password_again",
          placeholder: "Повторите ваш пароль",
          type: "password",
          error_text: "",
          show_error: false,
          show_success: false,
          show_password: false,
          model: "",
          active: false,
          touched: false,
          validators: [required(), equal("user_password", "Пароли не совпадают")],
          show_name: false
        }],
        login: [{
          title: "Email",
          name: "user_email_address",
          placeholder: "Введите ваш email",
          type: "email",
          error_text: "",
          show_error: false,
          show_success: false,
          model: "",
          active: false,
          touched: false,
          validators: [required()]
        }, {
          title: "Пароль",
          name: "user_password",
          placeholder: "Введите пароль",
          type: "password",
          error_text: "",
          show_error: false,
          show_success: false,
          show_password: false,
          model: "",
          active: false,
          touched: false,
          validators: [required()]
        }],
        restorepassword: [{
          title: "Email",
          name: "user_email_address",
          placeholder: "Введите ваш Email",
          type: "email",
          error_text: "",
          show_error: false,
          show_success: false,
          model: "",
          active: false,
          touched: false,
          validators: [required(), emailAvailable(this, true, "Пользователь не найден")]
        }]
      },
      codeTrys: SMS_CODE_TRY,
      codeSent: false,
      codeChecked: false,
      codeCheckStatus: false,
      phoneIsValid: false,
      fullPhone: "",
      restorepasswordComplete: false,
      timer: {
        minutes: null,
        seconds: 0
      },
      timerInstance: null,
      smsId: null,
      smsUserCode: null,
      qFakeSmsUserCode: 0
    };
  },
  async mounted() {
    this.active_inputs = [...this.inputs[this.action]];

    /* прямо сразу НЕ проверем плохие тел, ip
    //&:
    // if (
    //     this.action === "register" &&
    //     this.active_inputs.find((el) => el.name === "user_code")
    // ) {
    //     try {
    //         //console.log("mounted: -> checkTrySMS");
    //         await this.$store.dispatch("user/checkTrySMS");
    //     } catch (e) {
    //         //console.log("mounted: e=", e);
     //         //bad ip
    //         if (e.response?.status === 409) {
    //             //this.active_inputs.forEach - здесь нужно бы сделать disable всех полей
     //             this.codeTrys = -10;
    //             this.codeChecked = true;
    //             this.codeCheckStatus = false;
    //         }
    //     }
    // }
    */
  },
  methods: {
    async onSubmit() {
      let isValid;

      //console.log("onSubmit.1: isValid=", isValid, " this.codeCheckStatus=", this.codeCheckStatus);

      if (this.codeCheckStatus === 201) {
        isValid = true;
      } else {
        isValid = await this.validate(true);
        if (!isValid || this.action === "register" && this.active_inputs.find(el => el.name === "user_code") && !this.codeCheckStatus) {
          return;
        }
      }

      //console.log("onSubmit.2: isValid=", isValid, " this.codeCheckStatus=", this.codeCheckStatus);

      const afterLogin = async user => {
        if (this.action === "register") {
          // if( !window.sessionStorage.getItem("CQ_tarif") ){
          //     window.sessionStorage.setItem("CQ_tarif") = 1;

          //await CQ_register(user)
          await CQ_tarif(this.$api, user);
          //}

          try {
            await this.$telemetry.reachGoal("signup");
          } catch (e) {
            console.error(e);
          } finally {
            if (this.$route.params.forceBackRoute) {
              this.$router.push(this.$route.params.forceBackRoute);
            } else {
              if (this.$route.name === "Dashboard") {
                window.location.reload();
              } else {
                const backUrl = this.$route.query.backUrl;
                if (!backUrl) {
                  this.$router.push({
                    /* @V:
                    // name: "CategoryHome",
                    // params: {
                    //     mp: "wb",
                    //     showWelcomeModal: true,
                    // },
                    */
                    //&:
                    name: "Dashboard",
                    params: {
                      showWelcomeModal: true
                    }
                  });
                } else {
                  this.$router.push(backUrl);
                }
              }
            }
          }
          return;
        }

        //&:
        if (this.action === "login") {
          // //console.log(
          //     "Form.369: login.forceBackRoute=",
          //     this.$route.params.forceBackRoute
          // );

          // if( !window.sessionStorage.getItem("CQ_tarif") ){
          //     window.sessionStorage.setItem("CQ_tarif") = 1;
          await CQ_tarif(this.$api, user);
          //}

          if (this.$route.params.forceBackRoute) {
            this.$router.push(this.$route.params.forceBackRoute);
          } else {
            const backUrl = this.$route.query.backUrl;
            if (!backUrl) {
              this.$router.push({
                name: "Dashboard"
              });
            } else {
              this.$router.push(backUrl);
            }
          }
          return;
        }
        if (!["Login", "Register", "RestorePassword"].includes(this.$route.name)) {
          this.$router.go();
          return;
        }
        if (this.$route.params.forceBackRoute) {
          this.$router.push(this.$route.params.forceBackRoute);
        } else if (user.last_url) {
          this.$router.push({
            path: user.last_url
          });
        } else {
          this.$router.push(this.$route.params.backRoute || {
            path: "/"
          });
        }
      };
      if (this.action == "register") {
        // //console.log(
        //     "Form.551: this.active_inputs=",
        //     this.active_inputs
        // );

        const user = await this.$store.dispatch("user/signUp", {
          user_email_address: this.active_inputs.find(i => i.name === "user_email_address").model.trim().toLowerCase(),
          user_password: this.active_inputs.find(i => i.name === "user_password").model.trim(),
          user_phone: this.fullPhone,
          smsId: this.smsId,
          smsUserCode: this.smsUserCode
        });
        await afterLogin(user);
        return;
      }
      if (this.action == "login") {
        this.active_inputs.forEach(item => item.show_error = false);
        try {
          const user = await this.$store.dispatch("user/signIn", {
            user_email_address: this.active_inputs.find(i => i.name === "user_email_address").model.trim().toLowerCase(),
            user_password: this.active_inputs.find(i => i.name === "user_password").model.trim()
          });
          await afterLogin(user);
        } catch (e) {
          var _e$response3, _e$response4;
          if (((_e$response3 = e.response) === null || _e$response3 === void 0 ? void 0 : _e$response3.status) === 404) {
            const input = this.active_inputs.find(i => i.name === "user_email_address");
            input.error_text = "Пользователь не найден";
            input.show_error = true;
            input.show_success = false;
          } else if (((_e$response4 = e.response) === null || _e$response4 === void 0 ? void 0 : _e$response4.status) === 401) {
            const input = this.active_inputs.find(i => i.name === "user_password");
            input.error_text = "Неправильный пароль";
            input.show_error = true;
            input.show_success = false;
          }
          throw e;
        }
        return;
      }
      if (this.action == "restorepassword") {
        await this.$store.dispatch("user/restore", {
          user_email_address: this.active_inputs.find(i => i.name === "user_email_address").model.trim().toLowerCase()
        });
        this.restorepasswordComplete = true;

        // this.$router.push({
        //     name: 'Login',
        //     params: {
        //         message: 'Письмо с новым паролем отправлено на email'
        //     }
        // })

        return;
      }
    },
    // async saveBadRegister() {
    //     await this.$store.dispatch("user/saveBadTrySMS", {
    //             email: this.active_inputs
    //                 .find((i) => i.name === "user_email_address")
    //                 .model.trim()
    //                 .toLowerCase(),
    //             phone: this.fullPhone,
    //     });
    //     this.codeChecked = true;
    //     this.codeCheckStatus = false;
    // },

    startTimer(duration) {
      let timer = duration;
      this.timerInstance = setInterval(() => {
        this.timer.minutes = parseInt(timer / SMS_TIMER, 10);
        this.timer.seconds = parseInt(timer % SMS_TIMER, 10);
        this.timer.minutes = this.timer.minutes < 10 ? "0" + this.timer.minutes : this.timer.minutes;
        this.timer.seconds = this.timer.seconds < 10 ? "0" + this.timer.seconds : this.timer.seconds;
        if (--timer < 0) {
          timer = duration;
          clearInterval(this.timerInstance);

          //o:
          //this.codeTrys--;
          this.codeSent = false;
        }
      }, 1000);
    },
    async getCode(e) {
      e.preventDefault();
      this.codeTrys--;
      if (this.codeTrys <= 0) {
        //console.log("getCode(): ->saveBadRegister()")
        //await saveBadRegister();
        // async saveBadRegister() {
        await this.$store.dispatch("user/saveBadTrySMS", {
          email: this.active_inputs.find(i => i.name === "user_email_address").model.trim().toLowerCase(),
          phone: this.fullPhone
        });
        this.codeChecked = true;
        this.codeCheckStatus = false;
        // },
      }
      const res = await this.$store.dispatch("user/checkUserPhoneBySMS", {
        user_phone: this.fullPhone,
        user_password: this.active_inputs.find(i => i.name === "user_password").model.trim()
      });

      //console.log("getCode: res=", res);

      if (res) {
        this.smsId = res.data.id;
        clearInterval(this.timerInstance);
        this.startTimer(SMS_TIMER);
        this.codeSent = true;
        this.$nextTick(() => {
          this.$refs.codeInput[0].focus();
        });
      }
    },
    async onCodeInput(item) {
      if (item.model.length === SMS_CODE_LEN) {
        //this.codeTrys--;

        this.smsUserCode = item.model;
        if (this.codeTrys > 0) {
          let res = await this.$store.dispatch("user/checkSMSCode", {
            id: this.smsId,
            code: this.smsUserCode
          });

          /*
          // //console.log("onCodeInput: res=", res);
          // //console.log(
          //     "onCodeInput: res.data.success=",
          //     res.data.success
          // );
          */

          // let res = true;
          if (res) {
            this.codeChecked = true;
            if (res.data.success) {
              clearInterval(this.timerInstance);
              this.codeCheckStatus = true;
            } else {
              this.codeCheckStatus = false;
            }
          }
        }
      }
    },
    updatePhone(e) {
      //console.log('e=',e)
      this.fullPhone = e.formatInternational;
      this.phoneIsValid = e.isValid;
    }
  },
  computed: {
    title() {
      if (this.action == "login") return "Вход";
      if (this.action == "register") return "Регистрация";
      if (this.action == "restorepassword") return "Восстановление пароля";
      return "";
    },
    titleButton() {
      if (this.action == "login") return "Войти";
      if (this.action == "register") return "Зарегистрироваться";
      if (this.action == "restorepassword") return "Восстановить пароль";
      return "";
    },
    promocodeShow() {
      return window.sessionStorage.getItem("promocode:show") ? 1 : 0;
    },
    codeBtnText() {
      return this.codeSent ? "Отправить снова" : "Получить код";
    },
    codeText() {
      let text = "";
      if (this.codeTrys > 0) {
        if (!this.codeSent) {
          text = "Мы отправим СМС-код на указанный номер для подтверждения";
        } else if (this.codeTrys < SMS_CODE_TRY && Number(this.timer.seconds) === 0) {
          text = `Код отправлен на номер ${this.fullPhone} <br/>
                            Если СМС не поступило, вы можете запросить новый код через ${this.timer.seconds} сек <br/>
                            Осталось попыток: ${this.codeTrys}`;
        } else {
          text = `Код отправлен на номер ${this.fullPhone} <br/>
                            Запросить отправку нового кода можно через ${this.timer.seconds} сек`;
        }
      }
      return text;
    }
  },
  watch: {
    $route() {
      this.active_inputs = [...this.inputs[this.action]];
      this.active_inputs.forEach(input => {
        input.model = "";
      });
      this.restorepasswordComplete = false;
    }
  },
  components: {
    FadeTransition,
    Focus,
    UiForm,
    VuePhoneNumberInput,
    BillingPromocodeNotice
  }
};