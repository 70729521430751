/**
 * Form for external pages and popups
 */

export const required = (msg) => (v) => {
    if (!v?.length) {
        return msg || "Заполните это поле";
    }
};
export const minLength = (length, msg) => (v) => {
    if (v?.length < length) {
        return msg || `Минимум ${length} символов`;
    }
};
export const maxLength = (length, msg) => (v) => {
    if (v?.length > length) {
        return msg || `Максимум ${length} символов`;
    }
};
export const email = (msg) => (v) => {
    // regexp from https://emailregex.com/
    // https://html.spec.whatwg.org/multipage/input.html#input.email.attrs.value.multiple
    // eslint-disable-next-line
    const re =
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let valid_email = re.test(v);
    if (!valid_email) {
        return msg || "Похоже, email введен с ошибками";
    }
};
export const phone = (msg) => (v, items, phoneItem) => {
    // eslint-disable-next-line
    if (!phoneItem.phoneValid()) {
        return msg || "Похоже, номер введен с ошибками";
    }
};
export const symbols = (msg) => (v) => {
    // Символы ASCII от 32 до 126 включительно
    // eslint-disable-next-line
    const isValid = /^[\x20-\x7e]*$/.test(v);
    if (!isValid) {
        return msg || "Используйте латинские символы";
    }
};

export const equal = (name, msg) => (v, items) => {
    const item = items.find((i) => i.name === name);
    if (item.model.trim() !== v) {
        return msg || "Поля должны совпадать";
    }
};

export default {
    data() {
        return {
            active_inputs: [],
            fields: {},
        };
    },
    methods: {
        validationFeedback() {
            return true; // this.action !== 'login'
        },
        field(config = {}) {
            return {
                name: "",
                model: "",
                error_text: "",
                show_error: false,
                show_success: false,
                active: false,
                touched: false,
                validators: [],
                ...config,
            };
        },
        async validate(force = false) {
            let valid = true;
            const inputDefinitions = [];
            this.active_inputs.forEach((input) => inputDefinitions.push(input));
            Object.keys(this.fields).forEach((key) => {
                this.fields[key].name = this.fields[key].name || key;
                inputDefinitions.push(this.fields[key]);
            });
            inputDefinitions.forEach((input) => {
                if (this.validationFeedback()) {
                    input.show_error = false;
                    input.show_success = false;
                }
                if (force) {
                    input.touched = true;
                }
            });
            for (let input of inputDefinitions) {
                const validators = input.validators || [];
                const errors = await Promise.all(
                    validators.map(async (validator) => {
                        const errorMessage = await validator(
                            input.model ? input.model.trim() : "".trim(),
                            this.active_inputs,
                            input
                        );
                        if (errorMessage && !input.show_error) {
                            if (this.validationFeedback()) {
                                input.error_text = errorMessage;
                                input.show_error = true;
                            }
                        }
                        return errorMessage;
                    })
                );
                if (errors.some((error) => !!error)) {
                    valid = false;
                } else {
                    if (
                        this.validationFeedback() &&
                        input.validators &&
                        input.silentSuccess !== true
                    ) {
                        input.show_success = true;
                    }
                }
            }
            return valid;
        },
        onBlur(item) {
            item.active = false;
            if (item.validateOnBlur !== false) {
                item.touched = true;
            }
            this.validate();
        },
    },
};
